import React, { Component } from 'react';
import { withRouter, Link } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

import InputSearch from '../../component/common/InputSearch';
import NewButton from '../../component/common/NewButton';
import Paginate from '../../component/Paginate/index';
import Table from '../../component/common/TableStudent';
import SelectCustom from '../../component/common/SelectCustom';

import editIcon from '../../assets/images/icon/edit.svg';
import deleteIcon from '../../assets/images/images/delete.png';
import detail from '../../assets/images/images/detail.svg';
import accpect from '../../assets/images/accept.svg';

import {
  LIST_LIBRARY_REQUEST,
  DELETE_LIBRARY_REQUEST,
  POST_LIBRARY_REQUEST,
  GET_LIST_CATEGORY
} from '../../constants/config';
import {
  postDataWithParams,
  deleteById,
  putDataUrl,
  getTakenData
} from '../../services/base_services';
import { confirmDelete, showMessage, confirmPost } from '../../helpers/table';
import { formatDate } from '../../helpers/form';
import { PER_PAGE } from '../../constants/variable';
import Can from '../../services/Can';

class TableLibrary extends Component {
  constructor(props) {
    super(props);
    this.keyword = '';
    this.state = {
      isLoading: true,
      perPage: PER_PAGE,
      paginate: [],
      tableData: [],
      listCategory: [],
      initallCategory_id: '',
      tableHeaders: [
        {
          label: this.props.t('library.stt'),
          index: 'stt',
          option: {
            className: 'text-center position-relative'
          },
          callback: null
        },
        {
          label: this.props.t('library.title'),
          option: {
            className: 'width-300'
          },
          index: null,
          callback: (data) => {
            return (
              <div>
                <span>{data && data.title}</span>
              </div>
            );
          }
        },
        {
          label: this.props.t('library.sentDate'),
          index: null,
          option: {
            className: 'text-center width-200'
          },
          callback: (data) => {
            return (
              <div>
                <span>
                  {data && data.updated_at && formatDate(data.updated_at)}
                </span>
              </div>
            );
          }
        },
        {
          label: this.props.t('library.author'),
          index: null,
          option: {
            className: 'text-center width-200'
          },
          callback: (data) => {
            return (
              <div>
                <span>{data && data.user && data.user.name}</span>
              </div>
            );
          }
        },
        {
          label: this.props.t('library.documentType'),
          index: null,
          option: {
            className: 'text-center width-200'
          },
          callback: (data) => {
            return (
              <div>
                <span>{data && data.Catalog && data.Catalog.name}</span>
              </div>
            );
          }
        },
        {
          label: this.props.t('library.status'),
          index: null,
          option: {
            className: 'text-center width-150'
          },
          callback: (data) => {
            switch (data && data.status) {
              case 0:
                return (
                  <div>
                    <span>{this.props.t('library.saveDraft')}</span>
                  </div>
                );
              case 1:
                return (
                  <div>
                    <span>{this.props.t('library.pending')}</span>
                  </div>
                );
              case 2:
                return (
                  <div>
                    <span>{this.props.t('library.posted')}</span>
                  </div>
                );
              default:
                break;
            }
          }
        },
        {
          label: this.props.t('library.action'),
          index: null,
          option: {
            className: 'text-center fixed-collumn'
          },
          callback: (data) => {
            if (data.id) {
              return (
                <div className="btn-group">
                  <span
                    data-tip={this.props.t('library.details')}
                    className="link-action ml-2 mr-2"
                  >
                    <div
                      role="button"
                      tabIndex={0}
                      onClick={() => {
                        this.onDetailStudent(data.id);
                      }}
                      onKeyPress={() => {}}
                    >
                      <img alt="edit" src={detail} className="btn-icon" />
                    </div>
                  </span>
                  <Can I="library_document_edit" a="library">
                    <span
                      data-tip={this.props.t('library.edit')}
                      className="link-action ml-2 mr-2"
                    >
                      <div
                        role="button"
                        tabIndex={0}
                        onClick={() => {
                          this.onEditStudent(data.id);
                        }}
                        onKeyPress={() => {}}
                      >
                        <img alt="edit" src={editIcon} className="btn-icon" />
                      </div>
                    </span>
                  </Can>
                  <Can I="library_document_del" a="library">
                    <span
                      data-tip={this.props.t('library.delete')}
                      className="link-action ml-2"
                    >
                      <div
                        role="button"
                        tabIndex={0}
                        onClick={() => this.onDeleteStudent(data.id)}
                        onKeyPress={() => {}}
                      >
                        <img
                          alt="delete"
                          src={deleteIcon}
                          className="btn-icon"
                        />
                      </div>
                    </span>
                  </Can>
                  <ReactTooltip
                    className="tooltip-button"
                    place="bottom"
                    effect="float"
                  />
                </div>
              );
            }
            return '';
          }
        }
      ]
    };
  }

  componentDidMount = () => {
    this.getListCategory();
    this.getListDocument();
  };

  onEditStudent = (id) => {
    const { history } = this.props;
    history.push(`/active/libraries/edit/${id}`);
  };

  onDetailStudent = (id) => {
    const { history } = this.props;
    history.push(`/active/libraries/detail/${id}`);
  };

  onDeleteStudent = (id) => {
    confirmDelete().then((res) => {
      if (res.value === true) {
        this.removeStudentRequest(id);
      }
    });
  };

  removeStudentRequest = (id) => {
    deleteById(DELETE_LIBRARY_REQUEST, id)
      .then((res) => {
        showMessage(res.data.message, true);
        this.getListDocument();
      })
      .catch(() => {
        showMessage(this.props.t('library.error'), false);
      });
  };

  postDocument = (id) => {
    confirmPost().then((res) => {
      if (res.value === true) {
        this.postDocumentRequest(id);
      }
    });
  };

  postDocumentRequest = (id) => {
    putDataUrl(POST_LIBRARY_REQUEST, { id: id })
      .then((res) => {
        showMessage(res.data.message, true);
        this.getListDocument();
      })
      .catch(() => {
        showMessage(this.props.t('library.error'), false);
      });
  };

  getListCategory = async () => {
    await getTakenData(GET_LIST_CATEGORY).then((res) => {
      this.setState({
        listCategory: [
          {
            id: '',
            name: this.props.t('library.category')
          },
          ...res?.data?.map((item) => ({
            id: item?.id,
            name: item?.title
          }))
        ],
        initallCategory: ''
      });
    });
  };

  getListDocument = async () => {
    const { page, perPage } = this.state;
    const { location } = this.props;
    const queryParams = new URLSearchParams(location?.search);
    this.getListRequest({
      page: queryParams.get('page') || 1,
      per_page: queryParams.get('perpage') || perPage,
      keyword: this.keyword.search_text || '',
      catalog_id: 5,
      category_id: this.initallCategory_id || ''
    });
    this.setState({
      page: queryParams.get('page') || page,
      perPage: queryParams.get('perpage') || perPage
    });
  };

  getListRequest = async (params) => {
    const { perPage } = this.state;

    await postDataWithParams(LIST_LIBRARY_REQUEST, params)
      .then((res) => {
        const dataWithBranch = res?.data?.data.filter(
          (data) => data?.branch_id == this.props?.user?.user?.branch_id
        );
        const dataWithClub = res?.data?.data.filter(
          (data) => data?.club_id == this.props?.user?.user?.club_id
        );
        if (this?.props?.user?.user?.position_id == 2) {
          this.setState({
            tableData: dataWithBranch,
            paginate: {
              ...res?.data?.paginate,
              perPage: params?.per_page || perPage,
              page: res?.data?.paginate?.current_page
            },
            isLoading: false
          });
        } else if (this?.props?.user?.user?.position_id == 3) {
          this.setState({
            tableData: dataWithClub,
            paginate: {
              ...res?.data?.paginate,
              perPage: params?.per_page || perPage,
              page: res?.data?.paginate?.current_page
            },
            isLoading: false
          });
        } else {
          this.setState({
            tableData: res?.data?.data,
            paginate: {
              ...res?.data?.paginate,
              perPage: params?.per_page || perPage,
              page: res?.data?.paginate?.current_page
            },
            isLoading: false
          });
        }
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  };

  onChangeSearch = (e) => {
    clearTimeout(this.timer);
    const { page, perPage } = this.state;
    const { target } = e;
    const { name } = target;
    const value = target.value ? target.value : '';
    this.keyword = {
      ...this.keyword,
      [name]: value
    };
    this.timer = setTimeout(
      () =>
        this.getListRequest({
          page: 1,
          per_page: perPage,
          keyword: this.keyword.search_text || '',
          catalog_id: 5,
          category_id: this.state.initallCategory_id
        }),
      500
    );
    this.setState({
      page: 1
    });
  };

  onSubmitSearch = (e) => {
    e.preventDefault();
    clearTimeout(this.timer);
    const { page, perPage } = this.state;
    const { target } = e;
    const { name } = target;
    const value = target.value ? target.value : '';
    this.keyword = {
      ...this.keyword,
      [name]: value
    };
    this.timer = setTimeout(
      () =>
        this.getListRequest({
          page,
          per_page: perPage,
          keyword: this.keyword.search_text || '',
          catalog_id: 5,
          category_id: this.state.initallCategory_id
        }),
      500
    );
  };

  handleChangeCategory = (e) => {
    const { page, perPage } = this.state;
    this.getListRequest({
      page: page,
      per_page: perPage,
      keyword: this.keyword.search_text || '',
      category_id: e?.target?.value,
      catalog_id: 5
    });

    this.setState({ initallCategory_id: Number(e?.target?.value) });
  };

  updateURL = (pageNumber, perpage) => {
    const { history } = this.props;
    history.push(`?page=${pageNumber}&perpage=${perpage}`);
  };

  changePage = (pageNumber) => {
    const { page, perPage } = this.state;
    if (pageNumber !== page) {
      this.getListRequest({
        page: pageNumber,
        per_page: perPage,
        keyword: this.keyword.search_text || '',
        catalog_id: 5,
        category_id: this.state.initallCategory_id
      });
      this.setState({ page: pageNumber });
      this.updateURL(pageNumber, perPage);
    }
  };

  changePerPage = (value) => {
    this.setState({ perPage: value, page: 1 });
    this.getListRequest({
      page: 1,
      per_page: Number(value),
      keyword: this.keyword.search_text || '',
      catalog_id: 5,
      category_id: this.state.initallCategory_id
    });
    this.updateURL(1, value);
  };

  render() {
    const {
      tableHeaders,
      paginate,
      tableData,
      isLoading,
      listCategory,
      perPage
    } = this.state;
    return (
      <>
        <div className="body-right-bottom course-wrap">
          <div className="container-fluid container-shadow">
            <div className="row top-table">
              <div className="col-md-12 top-table-title">
                <p>{this.props.t('library.header')}</p>
              </div>
              <div className="col-md-1 top-table-border "></div>
              <div className="col-md-12 top-table-text">
                {' '}
                {this.props.t('library.document')}
              </div>
            </div>

            <div className="row header-wrap">
              <div className="col-sm-12 col-md-4 col-lg-4">
                <InputSearch
                  onChange={this.onChangeSearch}
                  onSubmitSearch={this.onSubmitSearch}
                />
              </div>
              <div className="col-sm-12 col-md-4 col-lg-4">
                <SelectCustom
                  className="form-control-select input-form form-control-product mb-3 input-grey"
                  name="category_id"
                  errors={[]}
                  key_value="id"
                  key_label="name"
                  data={listCategory}
                  onChange={this.handleChangeCategory}
                  defaultValue={this.state.initallCategory_id}
                />
              </div>
              <div className="col-sm-12 col-md-4 col-lg-4 content__flex-end">
                <Can I="library_document_add" a="library">
                  <Link
                    to={{
                      pathname: '/active/libraries/new',
                      query: { log_id: 5 }
                    }}
                  >
                    <NewButton />
                  </Link>
                </Can>
              </div>
            </div>

            <div className="row">
              <div className="col-md-12">
                <Table
                  fixed
                  tableHeaders={tableHeaders}
                  tableData={tableData}
                  tablePaginate={paginate}
                  isLoading={isLoading}
                />
              </div>
              <div className="table-pagenatie ml-auto">
                <div className="paginate-wrapper">
                  <Paginate
                    paginate={paginate}
                    changePage={this.changePage}
                    per_page={perPage || PER_PAGE}
                    tablePaginate={paginate}
                    changePerPage={this.changePerPage}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

TableLibrary.propTypes = {
  history: PropTypes.func.isRequired
};

const mapStateToProps = (state) => {
  return {
    user: state.user
  };
};

export default connect(
  mapStateToProps,
  null
)(withRouter(withTranslation()(TableLibrary)));
