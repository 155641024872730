import React, { useState, useEffect, useRef } from 'react';
import { useLocation, useHistory, Link, withRouter } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';
import { ReactSVG } from 'react-svg';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import {
  postDataWithParams,
  deleteById,
  putDataAcceptUser,
  getDataByID,
  getTakenData
} from '../../services/base_services';
import {
  LIST_STUDENT_REQUEST,
  DELETE_STUDENT_REQUEST,
  UPDATE_USER_APP,
  ACCEPT_USER,
  GET_LIST_BRANCH,
  GET_CLUB_BRANCH,
  GET_ALL_BELT_COURSE
} from '../../constants/config';
import { exportFileStudent } from '../../actions/club';
import { BASE_URL } from '../../constants/config';
import { confirmDelete, showMessage, checkUser } from '../../helpers/table';
import { formatDate } from '../../helpers/form';
import { PER_PAGE } from '../../constants/variable';
import Can from '../../services/Can';

import Select from '../../component/common/Select';
import InputText from '../../component/common/InputText';
import Datepicker from '../../component/common/Datepicker';
import InputSearch from '../../component/common/InputSearch';
import NewButton from '../../component/common/NewButton';
import Paginate from '../../component/Paginate/index';
import Table from '../../component/common/TableStudent';

import checkIcon from '../../assets/images/icon/box-ok-kn.svg';
import editIcon from '../../assets/images/icon/edit-kn.svg';
import deleteIcon from '../../assets/images/images/delete.png';
import uploadIcon from '../../assets/images/icon/upload-icon.svg';

import './Student.scss';

const TableStudent = (props) => {
  const { t } = useTranslation();
  const history = useHistory();
  const { user } = props;

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const [isLoading, setIsLoading] = useState(true);
  const [tableData, setTableData] = useState([]);
  const [paginate, setPaginate] = useState([]);
  const [perpage, setPerpage] = useState(
    queryParams.get('perpage') || PER_PAGE
  );

  const listStatus = [
    { id: '', name: t('student.status') },
    { id: 0, name: t('student.adminCreate') },
    { id: 1, name: t('student.register') }
  ];
  const [branch, setBranch] = useState([
    {
      id: 0,
      name: t('student.branch')
    }
  ]);
  const [club, setClub] = useState([
    {
      id: '',
      name: t('student.club')
    }
  ]);
  const listCoach = [
    { id: 0, name: t('student.all') },
    { id: 1, name: t('student.coach') }
  ];
  const [belt, setBelt] = useState([]);
  const [coach, setCoach] = useState(0);
  const [filter, setFilter] = useState({
    page: queryParams.get('page') || 1,
    per_page: queryParams.get('perpage') || perpage,
    keyword: '',
    age: '',
    status: '',
    branch_id: '',
    club_id: '',
    belt_id: '',
    join_date_from: '',
    join_date_to: '',
    coach_flg: false
  });

  const tableHeaders = [
    {
      label: t('student.stt'),
      index: 'stt',
      option: { className: 'text-center position-relative' },
      callback: (data) =>
        data?.index !== null ? (
          <div>
            <span className="text-center">{data?.index}</span>
          </div>
        ) : null
    },
    {
      label: t('student.name'),
      index: null,
      option: { className: 'width-200' },
      callback: (data) =>
        data && data?.name !== null ? (
          <div>
            <span className="">{data?.name}</span>
          </div>
        ) : null
    },
    {
      label: t('student.joinDate'),
      index: null,
      option: { className: 'text-center' },
      callback: (data) => (
        <div>
          <span>{data?.join_date ? formatDate(data?.join_date) : '-'}</span>
        </div>
      )
    },
    {
      label: t('student.belt'),
      index: null,
      option: { className: ' width-200 text-center' },
      callback: (data) => (
        <div className="d-flex justify-content-center">
          <span className={`belt-user-${data?.belt_id}`}></span>
          <span>{data?.infoBelt?.name}</span>
        </div>
      )
    },
    {
      label: t('student.status'),
      index: null,
      option: { className: 'text-center width-200' },
      callback: (data) => (
        <div className="d-flex justify-content-center">
          <span>
            {data?.regist_by === 1
              ? t('student.register')
              : t('student.adminCreate')}
          </span>
        </div>
      )
    },
    {
      label: t('student.ofClub'),
      index: null,
      option: { className: 'width-200 ' },
      callback: (data) =>
        data?.infoClub?.name ? (
          <div>
            <span>{data?.infoClub?.name}</span>
          </div>
        ) : (
          <div className="text-center">
            <span>{' - '}</span>
          </div>
        )
    },
    {
      label: t('student.actions'),
      index: null,
      option: { className: 'text-center fixed-collumn' },
      callback: (data) =>
        data?.id ? (
          <div className="btn-group">
            {data?.clubMember?.status === 0 && (
              <span
                data-tip={t('student.confirm')}
                className="link-action ml-2 mr-2"
              >
                <div
                  role="button"
                  tabIndex={0}
                  onClick={() => onCheckStudent(data?.id)}
                  onKeyPress={() => {}}
                >
                  <img alt="edit" src={checkIcon} className="btn-icon" />
                </div>
              </span>
            )}
            <Can I="user_edit" a="user">
              <span
                data-tip={t('student.edit')}
                className="link-action ml-2 mr-2"
              >
                <div
                  role="button"
                  tabIndex={0}
                  onClick={() => onEditStudent(data?.id)}
                  onKeyPress={() => {}}
                >
                  <img alt="edit" src={editIcon} className="btn-icon" />
                </div>
              </span>
            </Can>
            <Can I="user_del" a="user">
              <span data-tip={t('student.delete')} className="link-action ml-2">
                <div
                  role="button"
                  tabIndex={0}
                  onClick={() => onDeleteStudent(data?.id)}
                  onKeyPress={() => {}}
                >
                  <img alt="delete" src={deleteIcon} className="btn-icon" />
                </div>
              </span>
            </Can>
            <ReactTooltip
              className="tooltip-button"
              place="bottom"
              effect="float"
            />
          </div>
        ) : null
    }
  ];

  const onEditStudent = (id) => {
    history.push(`/statistical/student/edit/${id}`);
  };

  const onDeleteStudent = async (id) => {
    const confirmed = await confirmDelete();
    if (confirmed.value) {
      try {
        const res = await deleteById(DELETE_STUDENT_REQUEST, id);
        showMessage(res.data.message, true);
        postFilter(filter);
      } catch {
        showMessage(t('student.error'), false);
      }
    }
  };

  const onCheckStudent = async (id) => {
    const confirmed = await checkUser();
    if (confirmed.value) {
      try {
        const res = await putDataAcceptUser(UPDATE_USER_APP, ACCEPT_USER, id);
        showMessage(res.data.message, true);
        postFilter(filter);
      } catch {
        showMessage(t('student.error'), false);
      }
    }
  };

  const renderExcel = () => {
    exportFileStudent().then((res) => {
      if (res?.status === t('student.ok')) {
        window.open(`${BASE_URL}download?file=/uploads/users.csv&name=users`);
      }
    });
  };

  const postFilter = (param) => {
    postDataWithParams(LIST_STUDENT_REQUEST, param)
      .then((res) => {
        setIsLoading(false);
        setTableData(res?.data?.data);
        setPaginate({ ...res?.data?.paginate, perPage: perpage });
      })
      .catch((error) => showMessage(`${t('student.error')} ${error}`), false);
  };

  useEffect(() => {
    getBranch();
    getBelt();
  }, []);

  useEffect(() => {
    postFilter(filter);
  }, [filter]);

  const getBranch = () => {
    getTakenData(GET_LIST_BRANCH)
      .then((res) => {
        if (res?.data?.length == 1) {
          setBranch([...res?.data]);
          getClubByBranch(res?.data?.[0]?.id);
        } else {
          setBranch([
            {
              id: '',
              name: t('student.branch')
            },
            ...res?.data
          ]);
        }
      })
      .catch((error) => showMessage(`${t('dashboard.error')} ${error}`), false);
  };

  const getBelt = () => {
    getTakenData(GET_ALL_BELT_COURSE)
      .then((res) => {
        setBelt([
          {
            id: '',
            name: t('student.belt')
          },
          ...res?.data
        ]);
      })
      .catch((error) => showMessage(`${t('dashboard.error')} ${error}`), false);
  };

  const getClubByBranch = (id) => {
    getDataByID(GET_CLUB_BRANCH, id)
      .then((res) => {
        setClub([
          {
            id: '',
            name: t('student.club')
          },
          ...res?.data
        ]);
      })
      .catch((error) => showMessage(`${t('dashboard.error')} ${error}`), false);
  };

  const handleChangeBranch = (e) => {
    setFilter({ ...filter, page: 1, branch_id: e.target.value });
    if (e.target.value) {
      getClubByBranch(e.target.value);
    } else {
      setClub([
        {
          id: '',
          name: t('student.club')
        }
      ]);
    }
  };
  const handleChangeFilter = (e, nameKey) => {
    setFilter({ ...filter, page: 1, [nameKey]: e.target.value });
  };

  const updateURL = (pageNumber, perpage) => {
    history.push(`?page=${pageNumber}&perpage=${perpage}`);
  };

  const handleChangePage = (pageNumber) => {
    if (pageNumber !== paginate.current_page) {
      setPaginate({ ...paginate, page: pageNumber });
      updateURL(pageNumber, filter.per_page);
      setFilter({ ...filter, page: pageNumber });
    }
  };

  const changePerPage = (value) => {
    setPerpage(value);
    setPaginate({ ...paginate, perpage: value, page: 1 });
    updateURL(1, value);
    setFilter({ ...filter, per_page: value, page: 1 });
  };

  const handleChangeCoach = (e, nameKey) => {
    setCoach(e?.target?.value);
    if (e?.target?.value == 1) {
      return setFilter((prev) => {
        return { ...prev, page: 1, [nameKey]: true };
      });
    } else {
      return setFilter((prev) => {
        return { ...prev, page: 1, [nameKey]: false };
      });
    }
  };

  const handleChangeDateFrom = (date) => {
    if (date && date > new Date()) {
      showMessage(t('student.errorDate'));
      return;
    }
    setFilter({
      ...filter,
      page: 1,
      join_date_from: formatDate(date, 'yyyy-mm-dd')
    });
  };

  const handleChangeDateTo = (date) => {
    setFilter({ ...filter, join_date_to: formatDate(date, 'yyyy-mm-dd') });
  };
  return (
    <>
      <div className="body-right-bottom course-wrap student">
        <div className="container-fluid content">
          <div className="row top-table">
            <div className="col-md-12 top-table-title">
              <p>{t('student.header')}</p>
            </div>
            <div className="col-md-1 top-table-border"></div>
            <div className="col-md-12 top-table-text">
              {t('student.headerForm')}
            </div>
          </div>

          <div className="row header-wrap">
            <div className="col-sm-12 col-md-3">
              {user.position_id == 1 && (
                <Select
                  className="form-control-select input-form col-md-3"
                  name="name"
                  key_value="id"
                  key_label="name"
                  data={branch}
                  errors={[]}
                  value={filter.branch_id}
                  onChange={(e) => handleChangeBranch(e)}
                />
              )}
            </div>
            <div className="col-sm-12 col-md-3">
              {user.position_id != 3 && (
                <Select
                  className="form-control-select input-form col-md-3"
                  name="name"
                  key_value="id"
                  key_label="name"
                  data={club}
                  errors={[]}
                  value={filter.club_id}
                  onChange={(e) => handleChangeFilter(e, 'club_id')}
                />
              )}
            </div>
            <div className="col-sm-12 col-md-3">
              <Select
                className="form-control-select input-form col-md-3"
                name="name"
                key_value="id"
                key_label="name"
                data={belt}
                errors={[]}
                value={filter.belt_id}
                onChange={(e) => handleChangeFilter(e, 'belt_id')}
              />
            </div>
            <div className="col-sm-12 col-md-3">
              <Select
                className="form-control-select input-form col-md-3"
                name="name"
                key_value="id"
                key_label="name"
                data={listCoach}
                errors={[]}
                value={coach}
                onChange={(e) => handleChangeCoach(e, 'coach_flg')}
              />
            </div>
          </div>

          <div className="row header-wrap">
            <div className="col-sm-12 col-md-3">
              <InputText
                className="form-control input-form"
                placeholder={t('student.inputAge')}
                errors={[]}
                type="number"
                name="age"
                onChange={(e) => handleChangeFilter(e, 'age')}
              />
            </div>
            <div className="col-sm-12 col-md-3">
              <Select
                className="form-control-select input-form col-md-3"
                value={filter.status}
                errors={[]}
                key_value="id"
                key_label="name"
                data={listStatus}
                onChange={(e) => handleChangeFilter(e, 'status')}
              />
            </div>
            <div className="col-sm-12 col-md-3">
              <Datepicker
                name="join_date_from"
                value={filter.join_date_from}
                onChange={handleChangeDateFrom}
                placeholder={t('student.joinDateFrom')}
                errors={[]}
                clearIcon={false}
                className="form-control input-form"
                format="dd-MM-yyyy"
              />
            </div>
            <div className="col-sm-12 col-md-3">
              <Datepicker
                name="join_date_to"
                value={filter.join_date_to}
                onChange={handleChangeDateTo}
                placeholder={t('student.joinDateTo')}
                errors={[]}
                clearIcon={false}
                className="form-control input-form"
                format="dd-MM-yyyy"
              />
            </div>
          </div>

          <div className="row header-wrap">
            <div className="col-sm-6">
              <InputSearch onChange={(e) => handleChangeFilter(e, 'keyword')} />
            </div>
            <div className="col-sm-12 col-md-4 col-lg-4 content__flex-end">
              <button className="donate-list" onClick={renderExcel}>
                {t('student.exportFile')}
                <ReactSVG src={uploadIcon} className="naviga-icon" />
              </button>

              <Link to="/statistical/student/new">
                <NewButton />
              </Link>
            </div>
          </div>

          <div className="row header-wrap">
            <div className="col-md-12">
              <Table
                fixed
                tableHeaders={tableHeaders}
                tableData={tableData}
                tablePaginate={paginate}
                isLoading={isLoading}
              />
            </div>
            <div className="table-pagenatie ml-auto">
              <div className="paginate-wrapper">
                <Paginate
                  paginate={paginate}
                  changePage={handleChangePage}
                  per_page={perpage || PER_PAGE}
                  tablePaginate={paginate}
                  changePerPage={changePerPage}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <ReactTooltip className="tooltip-button" place="bottom" effect="float" />
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state?.user?.user
  };
};

TableStudent.propTypes = {
  history: PropTypes.object.isRequired
};

export default connect(mapStateToProps, null)(withRouter(TableStudent));
