/* eslint-disable react/display-name */
import React, { useState, useEffect, useRef } from 'react';
import propTypes from 'prop-types';
import { Link, withRouter, useLocation } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';
import {
  GET_LIST_POSTWEB,
  DELETE_POSTWEB,
  GET_LIST_MENU
} from '../../constants/config';
import { PER_PAGE } from '../../constants/variable';
import { confirmDelete } from '../../helpers/table';
import createNotification from '../../component/common/Notification';
import {
  postData,
  deleteById,
  getTakenData
} from '../../services/base_services';

import Table from '../../component/common/Table';
import Paginate from '../../component/Paginate';
import NewButton from '../../component/common/NewButton';
import InputSearch from '../../component/common/InputSearch';
import SelectCustom from '../../component/common/SelectCustom';
import editIcon from '../../assets/images/icon/edit-kn.svg';
import deleteIcon from '../../assets/images/icon/delete-kn.svg';

const Technique = (props) => {
  const { history } = props;
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const [tableData, setTableData] = useState([]);
  const [paginate, setPaginate] = useState([]);
  const [numberDelete, setNumberDelete] = useState(0);
  const typingTimeoutRef = useRef(null);
  const [key, setKey] = useState('');

  const [techniqueOption, setTechniqueOption] = useState([]);

  const [req, setReq] = useState({
    keyword: key,
    page: queryParams.get('page') || 1,
    per_page: queryParams.get('perpage') || PER_PAGE,
    event_id: '',
    date: '',
    type_menu: '1',
    menu_id: 0
  });

  const tableHeaders = [
    {
      label: 'STT',
      index: 'stt',
      option: {
        className: 'text-center position-relative'
      },
      callback: null
    },
    {
      label: 'Tiêu đề',
      index: null,
      callback: (data) => {
        return (
          <div>
            <span>{data && data.title}</span>
          </div>
        );
      }
    },
    {
      label: 'Loại Bài Viết',
      index: null,
      option: {
        className: 'text-center'
      },
      callback: (data) => {
        return (
          <div>
            <span>{data && data.menu_name}</span>
          </div>
        );
      }
    },
    {
      label: 'CHỨC NĂNG',
      index: null,
      option: {
        className: 'text-center fixed-collumn'
      },
      callback: (data) => {
        if (data && data.id) {
          return (
            <div className="btn-group">
              <span
                data-tip="Chỉnh Sửa Bài Viết"
                className="link-action ml-2 mr-2"
              >
                <div
                  role="button"
                  tabIndex={0}
                  onClick={() => onEditPostWeb(data.id)}
                  onKeyPress={() => {}}
                >
                  <img alt="edit" src={editIcon} className="btn-icon" />
                </div>
              </span>
              <span data-tip="Xóa Bài Viết" className="link-action ml-2">
                <div
                  role="button"
                  tabIndex={0}
                  onClick={() => onDeletePostWeb(data.id)}
                  onKeyPress={() => {}}
                >
                  <img alt="delete" src={deleteIcon} className="btn-icon" />
                </div>
              </span>
              <ReactTooltip
                className="tooltip-button"
                place="bottom"
                effect="float"
              />
            </div>
          );
        }
        return '';
      }
    }
  ];

  useEffect(() => {
    getTakenData(GET_LIST_MENU).then((res) => {
      const menuTechnique = res?.data?.find((technique) => technique?.id === 1)
        ?.submenus;
      setTechniqueOption([
        {
          id: 0,
          name: 'Loại bài viết'
        },
        ...menuTechnique
      ]);
    });
  }, []);

  const getListPostWeb = async (newReq) => {
    await postData(GET_LIST_POSTWEB, newReq)
      .then((res) => res && res.data)
      .then((res) => {
        setPaginate({ ...res?.paginate, perPage: req?.per_page });
        setTableData(res && res.data);
      });
  };

  const deletePostWeb = (id) => {
    deleteById(DELETE_POSTWEB, id).then(() => {
      setNumberDelete(numberDelete + 1);
      createNotification('success', `Xóa thông tin bài viết thành công!`);
    });
  };

  const onEditPostWeb = (id) => {
    history.push(`/postweb/edit/${id}`);
  };

  const onDeletePostWeb = (id, name) => {
    confirmDelete().then((res) => {
      if (res && res.value) {
        deletePostWeb(id, name);
      }
    });
  };

  const updateURL = (pageNumber, perpage) => {
    history.push(`?page=${pageNumber}&perpage=${perpage}`);
  };

  const changePage = (pageNumber) => {
    setReq({
      ...req,
      page: pageNumber
    });
    updateURL(pageNumber, req?.per_page);
  };

  const changePerPage = (value) => {
    setReq({
      ...req,
      page: 1,
      per_page: value
    });
    updateURL(1, value);
  };

  const onChangeSearch = (e) => {
    const value = e.target.value ? e.target.value : '';
    setKey(value);
    if (typingTimeoutRef.current) {
      clearTimeout(typingTimeoutRef.current);
    }
    typingTimeoutRef.current = setTimeout(() => {
      setReq({
        ...req,
        keyword: value,
        page: 1
      });
    }, 500);
  };

  const handleTechnique = (e) => {
    setReq({ ...req, menu_id: Number(e?.target?.value) });
  };

  useEffect(() => {
    getListPostWeb(req);
  }, [req, numberDelete]);

  return (
    <>
      <section className="body-right-bottom post-web-content-wrap course-wrap">
        <div className="container-fluid content">
          <div className="row top-table">
            <div className="col-md-12 top-table-title">
              <p>Quản Lý Bài Viết Trên Website </p>
            </div>
            <div className="col-md-1 top-table-border "></div>
          </div>

          <div className="row header-wrap">
            <div className="col-md-12 col-xl-8 row">
              <div className="col-6">
                <InputSearch onChange={onChangeSearch} />
              </div>
              <div className="col-6">
                <SelectCustom
                  className="input-form form-control-product input-grey"
                  errors={[]}
                  key_value="id"
                  key_label="name"
                  data={techniqueOption}
                  onChange={handleTechnique}
                  defaultValue={req?.menu_id || 0}
                />
              </div>
            </div>

            <div className="col-sm-12 col-md-4 col-lg-4 content__flex-end">
              <Link to="/postweb/new" className="btn-create">
                <NewButton />
              </Link>
            </div>
          </div>

          <div className="row">
            <div className="col-md-12">
              <Table
                tableData={tableData}
                tableHeaders={tableHeaders}
                tablePaginate={paginate}
              />
            </div>
            <div className="table-pagenatie ml-auto">
              <div className="paginate-wrapper">
                <Paginate
                  paginate={paginate}
                  changePage={changePage}
                  per_page={req?.per_page || PER_PAGE}
                  tablePaginate={paginate}
                  changePerPage={changePerPage}
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default withRouter(Technique);

Technique.propTypes = {
  history: propTypes.isRequired
};
